<template>
  <el-icon
    @click="getPost"
    class="text-primary text-hover-secondary cursor-pointer fs-4"
    ><Management
  /></el-icon>

  <el-drawer v-model="modalVisibility" direction="ltr">
    <template #header>
      <h4>{{ helpTitle }}</h4>
    </template>
    <template #default>
      <span id="knowledge-base-modal" v-html="helpBody"></span>
    </template>
    <template #footer> </template>
  </el-drawer>
</template>
<style>
#knowledge-base-modal > .hide-on-app {
  display: none;
}

#knowledge-base-modal img {
  max-width: 100%;
  height: auto;
}
</style>
<script lang="ts">
import axios from "axios";
import { defineComponent, ref } from "vue";
import { Management } from "@element-plus/icons-vue";
export default defineComponent({
  name: "kt-menu-component",
  props: { wordpressPostSlug: { type: String, required: true } },
  setup(props) {
    const modalVisibility = ref(false);
    const loading = ref(false);
    const helpTitle = ref();
    const helpBody = ref();

    const getPost = () => {
      loading.value = true;
      delete axios.defaults.headers.common["Remember-Token"];
      axios
        .get(
          `${
            import.meta.env.VITE_APP_AURORA_HELP_WORDPRESS
          }//wp-json/wp/v2/docs?slug=${props.wordpressPostSlug}`
        )
        .then((response) => {
          modalVisibility.value = true;
          loading.value = false;
          helpTitle.value = response.data[0].title.rendered;
          helpBody.value = response.data[0].content.rendered;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return {
      modalVisibility,
      getPost,
      Management,
      helpBody,
      loading,
      helpTitle,
    };
  },
});
</script>
