const AuthRoutes = [
  {
    path: "/",
    component: () => import("@/components/auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/SignIn.vue"),
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () => import("@/views/ForgotPassword.vue"),
      },
      {
        path: "/reset-password/:token/",
        name: "reset-password",
        component: () => import("@/views/ResetPassword.vue"),
      },
    ],
  },
];

export default AuthRoutes;
