import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Mutations } from "@/store/enums/StoreEnums";
import { HRMActions, HRMMutations } from "@/store/enums/StoreHRMEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import IUser from "@/store/interfaces/IUser";
import { displayServerError } from "@/helpers/alertHelpers";

@Module
export default class HRMModule extends VuexModule {
  hrmAnesthetists: IUser[] = [] as Array<IUser>;

  get hrmAnesthetist(): Array<IUser> {
    return this.hrmAnesthetists;
  }

  @Mutation
  [HRMMutations.ANESTHETIST.SET_LIST](data): void {
    this.hrmAnesthetists = data;
  }

  @Action
  [HRMActions.ANESTHETIST.LIST](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("hrm/anesthetists", { params: payload })
        .then(({ data }) => {
          this.context.commit(HRMMutations.ANESTHETIST.SET_LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Get Anesthetists list");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
