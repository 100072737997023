import HealthLinkApiService from "@/core/services/HealthLinkApiService";
import {
  HealthLinkActions,
  HealthLinkMutations,
} from "@/store/enums/StoreHealthLinkEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import IDoctorAddressBook from "@/store/interfaces/IDoctorAddressBook";
import { convertToDoctorAddressBook } from "@/helpers/HealthLinkAddressBookHelpers";
import { Mutations } from "@/store/enums/StoreEnums";
import {
  HealthLinkInfo,
  IHealthLink,
} from "@/store/interfaces/IHealthLinkInterfaces";

@Module
export default class HealthLinkModule
  extends VuexModule
  implements HealthLinkInfo
{
  healthLinkData = [] as Array<IHealthLink>;
  SelectHealthLinkData = {} as IDoctorAddressBook;

  /**
   * Get current user object
   * @returns AdminList
   */
  get healthLinksList(): Array<IHealthLink> {
    return this.healthLinkData;
  }

  get healthLinkSelected(): IDoctorAddressBook {
    return this.SelectHealthLinkData;
  }

  @Mutation
  [HealthLinkMutations.SET_LIST](healthFundsData) {
    this.healthLinkData = healthFundsData;
  }

  @Mutation
  [HealthLinkMutations.SET_SELECT](data) {
    this.SelectHealthLinkData = data;
  }

  @Action
  async [HealthLinkActions.LIST](payload) {
    HealthLinkApiService.setHeader();

    let providerParams = payload.name
      ? `practitioner.name=${payload.name}`
      : "";
    providerParams += payload.provider_no
      ? `&identifier=${HealthLinkApiService.providerUrl}${payload.provider_no}
      &_include=PractitionerRole:endpoint`
      : "";

    return HealthLinkApiService.query(
      `/PractitionerRole?location.address-country=Australia&${providerParams}`,
      { params: payload.params }
    )
      .then(({ data }) => {
        this.context.commit(HealthLinkMutations.SET_LIST, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.data);
        return Promise.reject();
      });
  }

  @Action
  async [HealthLinkActions.SELECT](payload) {
    HealthLinkApiService.setHeader();
    if (payload.provider_no) {
      const providerParams = `&identifier=${HealthLinkApiService.providerUrl}${payload.provider_no}
      &_include=PractitionerRole:endpoint`;
      return HealthLinkApiService.query(
        `/PractitionerRole?_include=PractitionerRole:practitioner&_include=
        PractitionerRole:organization&location.address-country=Australia&${providerParams}`,
        { params: payload.params }
      )
        .then(({ data }) => {
          this.context.commit(
            HealthLinkMutations.SET_SELECT,
            convertToDoctorAddressBook(data)
          );
        })
        .catch(() => {
          return Promise.reject();
        });
    }
  }
}
