<template>
  <a>
    <label :class="`fs-${textSize} text-primary`"
      >{{ heading }}: <span :class="`text-black fs-${textSize}`"><slot /></span
    ></label>
  </a>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    heading: { required: true, type: String },
    textSize: { type: Number, default: 5 },
  },
});
</script>
