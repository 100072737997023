import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import JwtService from "@/core/services/JwtService";
import IErrorReport from "@/store/interfaces/IErrorReport";

@Module
export default class ErrorModule extends VuexModule {
  error = {} as IErrorReport;
  errorList = [] as Array<IErrorReport>;

  /**
   * Get current error object
   * @returns errorData
   */
  get errorData(): IErrorReport {
    return this.error;
  }
  /**
   * Get error list as array
   * @returns errorList
   */
  get errorDataList(): Array<IErrorReport> {
    return this.errorList;
  }

  @Mutation
  [Mutations.SET_REPORT_ERROR.SELECT](data) {
    this.error = data;
  }

  @Mutation
  [Mutations.SET_REPORT_ERROR.LIST](data) {
    this.errorList = data;
  }

  @Action
  [Actions.ERROR.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("reports/error", payload)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          console.log(response, "Report an error");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
