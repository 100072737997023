<template>
  <router-link
    :to="link"
    class="nav-link text-active-primary me-6"
    active-class="active"
  >
    {{ label }}
  </router-link>
</template>
<script lang="ts">
export default {
  props: {
    label: { type: String, required: true },
    link: { type: String, required: true },
  },
};
</script>
