/* eslint-disable prettier/prettier */
const PatientFormActions = {
  LIST: "ListPatientForms",
  SHOW: "ShowPatientForm",
  TEMPLATE: {
    LIST: "ListPatientFormTemplate",
    CREATE: "CreatePatientFormTemplate",
    UPDATE: "UpdatePatientFormTemplateList",
    DELETE: "DeletePatientFormTemplate",
    VIEW: "viewPatientFormTemplate",
    ASSIGN: "assignPatientFormToAppointment",
  },
  DISPLAY: {
    ORG_DATA: "SetOrganizationUrl",
    VALIDATE: "ValidatePatientLastNameAndDateOfBirth",
    STORE: "STOREPatientLastNameAndDateOfBirth",
  },
  UPLOAD: {
    CREATE: "UploadPatientForm",
  },
  NTF: {
    RESEND: "resentPatientFormNotification",
  },
  NOTE: {
    CREATE: "CreatePatientFormNote",
    MARK_ACTIONED: "MarkPatientFormActive",
    DELETE: "DeletePatientFormNote",
  },
};

const PatientFormMutations = {
  SET_LIST: "setPatientFormList",
  SET_SELECTED_PATIENT_FORM: "setSelectedPatientForm",
  TEMPLATE: {
    SET_LIST: "setPatientFormTemplateList",
    SET: "setPatientFormTemplate",
  },
  DISPLAY: {
    ORG_DATA: "SetOrganizationLogoUrl",
    VALIDATE: {
      DATA: "SetValidatePatientLastNameAndDateOfBirth",
    },
  },
  SET_FILTER: "setFilterList",
};

export { PatientFormActions, PatientFormMutations };
