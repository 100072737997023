import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Mutations } from "@/store/enums/StoreEnums";
import { HRMActions, HRMMutations } from "@/store/enums/StoreHRMEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { displayServerError } from "@/helpers/alertHelpers";
import { IFilledWeek } from "@/store/interfaces/IFilledWeek";
import store from "@/store";

@Module
export default class HRMModule extends VuexModule {
  hrmFilledWeeksList = [] as Array<IFilledWeek[]>;

  get HrmFilledWeeks(): Array<IFilledWeek[]> {
    return this.hrmFilledWeeksList;
  }

  @Mutation
  [HRMMutations.FillED_WEEKS.SET_LIST](data) {
    this.hrmFilledWeeksList = data;
  }

  @Action
  [HRMActions.FillED_WEEKS.LIST](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("hrm/filled-weeks", { params: params })
        .then(({ data }) => {
          store.commit(HRMMutations.FillED_WEEKS.SET_LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Display published weeks");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
