import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import AuthRoutes from "./AuthRoutes";
import AppointmentRoutes from "./AppointmentRoutes";
import BillingRoutes from "./BillingRoutes";
import MailboxRoutes from "./MailboxRoutes";
import PatientRoutes from "./PatientRoutes";
import ClaimRoutes from "./ClaimRoutes";
import SettingsRoutes from "./SettingsRoutes";
import HRMRoutes from "./HRMRoutes";
import DocumentRoutes from "./DocumentRoutes";
import ClinicRoutes from "./ClinicRoutes";
import OrganizationRoutes from "./OrganizationRoutes";
import PatientFormRoutes from "./PatientFormRoutes";
import ProcedureInstructionRoutes from "./ProcedureInstructionRoutes";
import MiscRoutes from "./MiscRoutes";
import ImportPatientRoutes from "./ImportPatientRoutes";
import { computed } from "vue";
import moment from "moment";
import CodingRoutes from "./CodingRoutes";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/admins",
        name: "administrator",
        component: () => import("@/views/settings/admins/Admins.vue"),
      },
      ...CodingRoutes,
      ...OrganizationRoutes,
      ...ClinicRoutes,
      ...DocumentRoutes,
      ...HRMRoutes,
      ...SettingsRoutes,
      ...PatientRoutes,
      ...ClaimRoutes,
      ...MailboxRoutes,
      ...AppointmentRoutes,
      ...BillingRoutes,
      ...ImportPatientRoutes,
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/components/auth/Profile.vue"),
      },
      {
        path: "/profile/password-change",
        name: "password-change",
        component: () => import("@/components/auth/Password.vue"),
      },
      {
        path: "/profile/signature",
        name: "signature",
        component: () => import("@/components/auth/Signature.vue"),
      },
      {
        path: "/profile/auto-texts",
        name: "auto-texts",
        component: () => import("@/components/auth/AutoTextSettings.vue"),
      },
      {
        path: "/employee-booking-dashboard",
        name: "employee-booking-dashboard",
        component: () => import("@/views/appointments/EmployeeBookings.vue"),
      },

      ////////////////////////////////////////////////////////////////////////
      // Patient Forms
      {
        path: "/communication/patient-forms",
        name: "patient-forms",
        component: () =>
          import("@/views/patient-forms/ListAllPatientForms.vue"),
      },
    ],
  },
  ...AuthRoutes,
  ...ProcedureInstructionRoutes,
  ...MiscRoutes,
  ...PatientFormRoutes,
  {
    path: "/appointment/:id/confirm",
    name: "appointment-patient-confirm",
    component: () =>
      import("@/views/appointments/AppointmentPatientConfirm.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  const publicRoutes = ["reset-password", "forgot-password", "sign-in"];

  if (publicRoutes.includes(<string>to.name)) {
    next();
  }

  await verifyToken();

  const isUserAuthenticated = computed(() => store.getters.isUserAuthenticated);
  const user = computed(() => store.getters.currentUser);
  const organization = computed(() => store.getters.userOrganization);
  const allowedRoutes = ["my-schedule", "sign-in"];

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (!isUserAuthenticated.value && !publicRoutes.includes(<string>to.name)) {
    next({ name: "sign-in" });
  }

  if (isUserAuthenticated.value && !user.value.outside_hours) {
    if (
      allowedRoutes.includes(<string>to.name) ||
      user.value.role == "organizationAdmin"
    ) {
      next();
    }

    const orgStartTime = moment(organization.value.start_time, "HH:mm:ss");
    const orgEndTime = moment(organization.value.end_time, "HH:mm:ss");

    return moment().isAfter(orgStartTime) && moment().isBefore(orgEndTime);
  }
  next();
});

const verifyToken = () => {
  return new Promise(function (resolve) {
    store
      .dispatch(Actions.VERIFY_AUTH, {
        api_token: JwtService.getToken(),
      })
      .then(() => {
        resolve("");
      });
  });
};

export default router;
