import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { CodingActions, CodingMutations } from "@/store/enums/StoreCodingEnums";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { displayServerError } from "@/helpers/alertHelpers";
import CodingApiService from "@/core/services/CodingApiService";

interface IApt {
  id: number;
}

interface AptInfo {
  aptData: Array<IApt>;
  aptSelectData: IApt;
}

@Module
export default class CodingModule extends VuexModule implements AptInfo {
  aptData = [] as Array<IApt>;
  aptSelectData = {} as IApt;

  /**
   * Get list of appointment to be coded
   * @returns AdminList
   */
  get getCodingAptList(): Array<IApt> {
    return this.aptData;
  }

  /**
   * Get selected appointment to be coded
   * @returns AdminData
   */
  get getCodingAptSelect(): IApt {
    return this.aptSelectData;
  }

  @Mutation
  [CodingMutations.SET_LIST](aptData) {
    this.aptData = aptData;
  }

  @Mutation
  [CodingMutations.SET_SELECT](aptData) {
    this.aptSelectData = aptData;
  }

  @Action
  [CodingActions.LIST](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("coding", { params: params })
        .then(({ data }) => {
          this.context.commit(CodingMutations.SET_LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing all codings");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  async [CodingActions.SEARCH_DIAGNOSES](params) {
    if (await CodingApiService.setHeader()) {
      return CodingApiService.query("disease-codes", {
        params: params,
      })
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          return Promise.reject();
        });
    }
  }

  @Action
  async [CodingActions.SEARCH_ADMINISTRATION](params) {
    if (await CodingApiService.setHeader()) {
      return CodingApiService.query("administration-codes", {
        params: params,
      })
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          return Promise.reject();
        });
    }
  }

  @Action
  async [CodingActions.SHOW_DIAGNOSES](code_id: string) {
    if (await CodingApiService.setHeader()) {
      return CodingApiService.get("disease-codes/" + code_id)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          return Promise.reject();
        });
    }
  }
  @Action
  async [CodingActions.SHOW_ADMINISTRATION](code_id: string) {
    if (await CodingApiService.setHeader()) {
      return CodingApiService.get("administration-codes/" + code_id)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          return Promise.reject();
        });
    }
  }

  @Action
  async [CodingActions.SEARCH_MORPHOLOGY](params) {
    if (await CodingApiService.setHeader()) {
      return CodingApiService.query("morphology-codes", {
        params: params,
      })
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          return Promise.reject();
        });
    }
  }

  @Action
  [CodingActions.CHECK_APPOINTMENTS_COMPLETE](data) {
    return ApiService.post("coding/check-appointments-complete", data)
      .then(({ data }) => {
        return data.data;
      })
      .catch(({ response }) => {
        return displayServerError(
          response,
          "Marking appointment codes as complete"
        );
      });
  }

  @Action
  async [CodingActions.GENERATE_CODING_REPORT](payload) {
    if (await CodingApiService.setHeader()) {
      CodingApiService.setHeader();
      return CodingApiService.post(
        "generateReport",
        payload //,
        //{
        //  responseType: "blob",
        //}
      )
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Generating a coding report");
        });
    } else {
      this.context.commit(CodingMutations.PURGE_CODING_TOKEN);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_CODES.LIST](appointmentId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("coding/disease-codes/", {
        params: { appointment_id: appointmentId },
      })
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing all appointment codes");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_ADMINISTRATION_CODES.LIST](appointmentId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("coding/administration-codes/", {
        params: { appointment_id: appointmentId },
      })
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Listing all administration codes"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_CODES.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("coding/disease-codes/", payload)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing all appointment codes");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_ADMINISTRATION_CODES.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("coding/administration-codes/", payload)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Listing all administration codes"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_CODES.DELETE](appointmentCodeId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete("coding/disease-codes/" + appointmentCodeId)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Deleting an appointment codes");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_ADMINISTRATION_CODES.DELETE](codeId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete("coding/administration-codes/" + codeId)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Deleting an appointment administration codes"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_CODES.UPDATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put("coding/disease-codes/" + payload.id, payload)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Updating an appointment code");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_ADMINISTRATION_CODES.UPDATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(
        "coding/administration-codes/" + payload.id,
        payload
      )
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Updating an appointment administration code"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_ADMINISTRATION_FORMS.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("coding/administration-forms/", payload)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Create administration form");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [CodingActions.APPOINTMENT_ADMINISTRATION_FORMS.UPDATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(
        "coding/administration-forms/" + payload.id,
        payload
      )
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Updating an appointment administration code"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
