const PatientFormRoutes = [
  {
    path: "/:shortCode",
    name: "patient-form-view",
    component: () => import("@/views/patient-forms/patient-forms-view.vue"),
    children: [
      {
        path: ":token",
        name: "patient-form-1",
        component: () => import("@/components/patient-forms/Form1.vue"),
      },
      {
        path: ":token/form_2",
        name: "patient-form-2",
        component: () => import("@/components/patient-forms/Form2.vue"),
      },
      {
        path: ":token/form_3",
        name: "patient-form-form-3",
        component: () => import("@/components/patient-forms/Form3.vue"),
      },
    ],
  },
];

export default PatientFormRoutes;
