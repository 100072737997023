import BillingApiService from "@/core/services/BillingApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { IHealthFund } from "@/store/interfaces/IHealthFund";

export interface HealthFundsInfo {
  healthFundsData: Array<IHealthFund>;
}

@Module
export default class HealthFundsModule
  extends VuexModule
  implements HealthFundsInfo
{
  healthFundsData = [] as Array<IHealthFund>;

  /**
   * Get current user object
   * @returns AdminList
   */
  get healthFundsList(): Array<IHealthFund> {
    return this.healthFundsData;
  }

  @Mutation
  [Mutations.SET_HEALTH_FUND.LIST](healthFundsData: Array<IHealthFund>) {
    this.healthFundsData = healthFundsData;
  }

  @Action
  async [Actions.HEALTH_FUND.LIST]() {
    if (await BillingApiService.setHeader()) {
      return BillingApiService.get("api/health-funds")
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_HEALTH_FUND.LIST, data.data);
          } else {
            throw data;
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          return Promise.reject();
        });
    }
  }
}
