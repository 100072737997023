import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import {
  displayServerError,
  displaySuccessToast,
} from "@/helpers/alertHelpers";
import IPatientFormTemplate from "../interfaces/IPatientFormTemplate";
import {
  PatientFormActions,
  PatientFormMutations,
} from "../enums/StorePatientFormEnums";

@Module
export default class PatientFormTemplate extends VuexModule {
  patientFormTemplates = [] as Array<IPatientFormTemplate>;
  patientFormTemplateData = {} as IPatientFormTemplate;

  get getPatientFormTemplates(): Array<IPatientFormTemplate> {
    return this.patientFormTemplates;
  }

  get getPatientFormSelectedTemplate(): IPatientFormTemplate {
    return this.patientFormTemplateData;
  }

  @Mutation
  [PatientFormMutations.TEMPLATE.SET_LIST](patientFormTemplates) {
    this.patientFormTemplates = patientFormTemplates;
  }

  @Mutation
  [PatientFormMutations.TEMPLATE.SET](patientFormTemplate) {
    this.patientFormTemplateData = patientFormTemplate;
  }

  @Action
  [PatientFormActions.TEMPLATE.LIST]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("patient-form-templates")
        .then(({ data }) => {
          this.context.commit(
            PatientFormMutations.TEMPLATE.SET_LIST,
            data.data
          );
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Listing all patient form templates"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [PatientFormActions.TEMPLATE.VIEW](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("patient-form-templates/" + id)
        .then(({ data }) => {
          this.context.commit(PatientFormMutations.TEMPLATE.SET, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing patient form template");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [PatientFormActions.TEMPLATE.CREATE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("patient-form-templates", data)
        .then(() => {
          this.context.dispatch(PatientFormActions.TEMPLATE.LIST);
          return displaySuccessToast("Patient Form Template Created");
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Creating a patient form template"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [PatientFormActions.TEMPLATE.UPDATE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.update("patient-form-templates", data.id, data)
        .then(() => {
          this.context.dispatch(PatientFormActions.TEMPLATE.LIST);
          return displaySuccessToast("Patient Form Template Updated");
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Updating a patient form template"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [PatientFormActions.TEMPLATE.DELETE](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.delete("patient-form-templates/" + id)
        .then(() => {
          this.context.dispatch(PatientFormActions.TEMPLATE.LIST);
          return displaySuccessToast("Patient Form Template Deleted");
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Deleting a patient form template"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
