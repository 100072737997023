import ApiService from "@/core/services/ApiService";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { displayServerError } from "@/helpers/alertHelpers";
import IPatientFormTemplate from "../interfaces/IPatientFormTemplate";
import {
  PatientFormActions,
  PatientFormMutations,
} from "../enums/StorePatientFormEnums";
import Swal from "sweetalert2";
import router from "@/router";
import IOrgData from "@/store/interfaces/IDisplayPatientForm";

@Module
export default class PatientFormDisplay extends VuexModule {
  orgData = {} as IOrgData;
  patientFormValidateData = {} as IPatientFormTemplate;

  get organizationLogoUrl(): IOrgData {
    return this.orgData;
  }

  get getPatientFormValidateData(): IPatientFormTemplate {
    return this.patientFormValidateData;
  }

  @Mutation
  [PatientFormMutations.DISPLAY.VALIDATE.DATA](data) {
    this.patientFormValidateData = data;
  }

  @Mutation
  [PatientFormMutations.DISPLAY.ORG_DATA](data) {
    this.orgData = data;
  }

  @Action
  [PatientFormActions.DISPLAY.ORG_DATA](payload) {
    ApiService.post("patient-display-forms/org-logo/", payload)
      .then(({ data }) => {
        this.context.commit(PatientFormMutations.DISPLAY.ORG_DATA, data.data);
      })
      .catch(({ response }) => {
        return displayServerError(response, "Display organisation logo");
      });
  }

  @Action
  [PatientFormActions.DISPLAY.VALIDATE](payload) {
    ApiService.post("patient-display-forms/validate", payload)
      .then(({ data }) => {
        this.context.commit(
          PatientFormMutations.DISPLAY.VALIDATE.DATA,
          data.data
        );
        router.push({
          path: `/${payload.short_code}/${payload.token}/form_2`,
        });
        return data.message;
      })
      .catch(({ response }) => {
        if (response.status === 403) {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } else {
          return displayServerError(
            response,
            "Validating patient form form view"
          );
        }
      });
  }

  @Action
  [PatientFormActions.DISPLAY.STORE](payload) {
    ApiService.post("patient-display-forms/" + payload.token, payload)
      .then(() => {
        router.push({
          path: `/${payload.short_code}/${payload.token}/form_3`,
        });
      })
      .catch(({ response }) => {
        return displayServerError(response, "storing a patient form form data");
      });
  }
}
