<template>
  <div class="d-flex justify-content-end flex-row gap-2">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    column: { type: Boolean, default: false },
  },
};
</script>
