import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  PatientActions,
  PatientMutations,
} from "@/store/enums/StorePatientEnums";
import { displayServerError } from "@/helpers/alertHelpers";
import { INotificationLog } from "@/assets/ts/components/_patientNotificationLog";

export interface NotificationLogInfo {
  notificationData: Array<INotificationLog>;
}
@Module
export default class PatientsNotificationLogModule
  extends VuexModule
  implements NotificationLogInfo
{
  notificationData = [] as Array<INotificationLog>;
  notificationSelectedData = {} as INotificationLog;

  /**
   * Get current outgoing log List
   * @returns Outgoing
   */
  get getPatientNotificationLogList(): Array<INotificationLog> {
    return this.notificationData;
  }

  get getSelectedPatientNotificationLog(): INotificationLog {
    return this.notificationSelectedData;
  }

  @Mutation
  [PatientMutations.SET_NOTIFICATION_LOG.LIST](data) {
    this.notificationData = data;
  }

  @Mutation
  [PatientMutations.SET_NOTIFICATION_LOG.SELECT](data) {
    this.notificationSelectedData = data;
  }

  @Action
  [PatientActions.NOTIFICATION_LOG.LIST]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("reports/notification-logs")
        .then(({ data }) => {
          this.context.commit(
            PatientMutations.SET_NOTIFICATION_LOG.LIST,
            data.data
          );
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Listing all patient notification logs");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
