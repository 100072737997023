import BillingApiService from "@/core/services/BillingApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import IPhaIllnessItem from "@/store/interfaces/IPhaIllnessItem";

export interface PhaIllnessItemResponse {
  success: boolean;
  data: PhaIllnessItemDataResponse;
}

export interface PhaIllnessItemDataResponse {
  total: number;
  items: Array<IPhaIllnessItem>;
}

export interface PhaIllnessItemInfo {
  errors: unknown;
  phaIllnessItemResponse: PhaIllnessItemResponse;
}

@Module
export default class PhaIllnessModule
  extends VuexModule
  implements PhaIllnessItemInfo
{
  errors = {};
  phaIllnessItemResponse = {} as PhaIllnessItemResponse;

  /**
   * Get PHA item response
   * @returns PhaIllnessItemResponse
   */
  get phaIllnessItems(): PhaIllnessItemDataResponse {
    return this.phaIllnessItemResponse.data;
  }

  @Mutation
  [Mutations.SET_PHA_ILLNESS.LIST](data) {
    this.phaIllnessItemResponse = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error, status: "failed" };
  }

  @Action
  async [Actions.PHA_ILLNESS.LIST]() {
    if (await BillingApiService.setHeader()) {
      return BillingApiService.get("api/items/pha-illness")
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_PHA_ILLNESS.LIST, data);
          } else {
            throw data;
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          return Promise.reject();
        });
    }
  }
}
