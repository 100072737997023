import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Mutations } from "@/store/enums/StoreEnums";
import {
  DocumentActions,
  DocumentMutations,
} from "@/store/enums/StoreDocumentEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  displayServerError,
  displaySuccessToast,
} from "@/helpers/alertHelpers";
import IDocument from "../interfaces/IDocument";

export interface Documents {
  documentsData: Array<IDocument>;
  selectedDocument: unknown;
  selectedDocumentType: string;
}

@Module
export default class DocumentModule extends VuexModule implements Documents {
  documentsData = [] as Array<IDocument>;
  selectedDocument = null;
  selectedDocumentType = "";

  get documentsList(): Array<IDocument> {
    return this.documentsData;
  }

  get getSelectedDocument(): unknown {
    return this.selectedDocument;
  }

  get getSelectedDocumentType(): string {
    return this.selectedDocumentType;
  }

  @Mutation
  [DocumentMutations.SET_LIST](data) {
    this.documentsData = data;
  }

  @Mutation
  [DocumentMutations.SET_SELECTED_DOCUMENT](data = null) {
    this.selectedDocument = data;
  }

  @Mutation
  [DocumentMutations.SET_SELECTED_DOCUMENT_TYPE](data) {
    this.selectedDocumentType = data;
  }

  @Action
  [DocumentActions.LIST](data) {
    if (JwtService.getToken()) {
      this.context.commit(DocumentMutations.SET_LIST, []);
      ApiService.setHeader();
      return ApiService.query("documents", { params: data })
        .then(({ data }) => {
          this.context.commit(DocumentMutations.SET_LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing patient documents");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [DocumentActions.SHOW](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("documents", id)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Retrieving a patient document");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [DocumentActions.UPDATE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`documents/${data.document_id}`, data)
        .then(() => {
          return displaySuccessToast("Document updated successfully");
        })
        .catch(({ response }) => {
          displayServerError(response, "Updating a patient document");
          return Promise.reject();
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [DocumentActions.SAVE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("patients/documents/save/" + data.patient_id, data)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Saving a patient document");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [DocumentActions.PATIENT_PREVIEW](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(
        "patients/documents/preview/" + data.patient_id,
        data,
        {
          responseType: "blob",
        }
      )
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "previewing a patient document");
        });
    }
  }

  @Action
  [DocumentActions.IMAGE_UPLOAD](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("patients/documents/image-upload/", data)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Upload images");
        });
    }
  }

  @Action
  [DocumentActions.IMAGE_DELETE](imageId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`patients/documents/image-delete/${imageId}`)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Delete images");
        });
    }
  }
}
