import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import {
  displayServerError,
  displaySuccessToast,
} from "@/helpers/alertHelpers";
import {
  PatientFormActions,
  PatientFormMutations,
} from "../enums/StorePatientFormEnums";
import IPatientForm from "../interfaces/IPatientForm";

@Module
export default class PatientFormModule extends VuexModule {
  patientForms = [] as Array<IPatientForm>;
  selectedPatientForm: IPatientForm | null = null;

  get getPatientForms(): Array<IPatientForm> {
    return this.patientForms;
  }

  get getSelectedPatientForm(): IPatientForm | null {
    return this.selectedPatientForm;
  }

  @Mutation
  [PatientFormMutations.SET_SELECTED_PATIENT_FORM](patientForm) {
    this.selectedPatientForm = patientForm;
  }

  @Mutation
  [PatientFormMutations.SET_LIST](patientForms) {
    this.patientForms = patientForms;
  }

  @Mutation [PatientFormMutations.SET_FILTER](appointmentId) {
    this.patientForms = this.patientForms.filter(
      (patientForm) =>
        patientForm.appointment && patientForm.appointment.id === appointmentId
    );
  }

  @Action
  [PatientFormActions.SHOW](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(`patient-forms/${id}`)
        .then(({ data }) => {
          this.context.commit(
            PatientFormMutations.SET_SELECTED_PATIENT_FORM,
            data.data
          );
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Showing a single patient forms");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [PatientFormActions.LIST](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.query("patient-forms", params)
        .then(({ data }) => {
          this.context.commit(PatientFormMutations.SET_LIST, data.data);
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing all patient forms");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [PatientFormActions.NOTE.CREATE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(`patient-forms/${data.patient_form_id}/notes`, data)
        .then(() => {
          return displaySuccessToast("Note Created");
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing all patient forms");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [PatientFormActions.NOTE.MARK_ACTIONED](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.put(`patient-forms/notes/${data.note_id}`, null)
        .then(() => {
          return displaySuccessToast("Note Actioned");
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing all patient forms");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [PatientFormActions.NOTE.DELETE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.delete(`patient-forms/notes/${data.note_id}`)
        .then(() => {
          return displaySuccessToast("Note Deleted");
        })
        .catch(({ response }) => {
          return displayServerError(response, "Deleting patient form note");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [PatientFormActions.UPLOAD.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(`patient-forms/upload/`, payload)
        .then(() => {
          return displaySuccessToast("Patient Form Upload Successfully");
        })
        .catch(({ response }) => {
          return displayServerError(response, "uploading Patient Form");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [PatientFormActions.NTF.RESEND](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`patient-forms/resend-form/`, payload)
        .then(() => {
          return displaySuccessToast("Patient Form Resend Successfully");
        })
        .catch(({ response }) => {
          displayServerError(response, "resending Patient Form");
          return Promise.reject();
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [PatientFormActions.TEMPLATE.ASSIGN](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(
        `patient-forms/assign-form/${payload.appointment_id}`,
        payload
      )
        .then((data) => {
          return data;
        })
        .catch(({ response }) => {
          displayServerError(
            response,
            "Create and assign Patient Form to appointment"
          );
          return Promise.reject();
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
