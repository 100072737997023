import BillingApiService from "@/core/services/BillingApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  PatientActions,
  PatientMutations,
} from "@/store/enums/StorePatientEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import IBillingResponse from "@/store/interfaces/IBillingResponse";
import { displayServerError } from "@/helpers/alertHelpers";

export interface ValidationDataResponse {
  verified: boolean;
  update_details: Array<string>;
  message: string;
  errors: Array<string>;
}

export interface ValidationInfo {
  errors: unknown;
  sourceValidationResponse: IBillingResponse<ValidationDataResponse>;
  isVerified: boolean;
}

@Module
export default class BillingValidationModule
  extends VuexModule
  implements ValidationInfo
{
  errors = {};
  sourceValidationResponse = {} as IBillingResponse<ValidationDataResponse>;
  isVerified = false;

  /**
   * Get medicare validation response
   * @returns IBillingResponse
   */
  get validationResponse(): IBillingResponse<ValidationDataResponse> {
    return this.sourceValidationResponse;
  }

  @Mutation
  [PatientMutations.CLAIM_SOURCE.SET_VALIDATION](data) {
    this.sourceValidationResponse = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error, status: "failed" };
  }

  @Action
  async [Actions.SCHEDULE_ITEM.VERIFY](items) {
    if (await BillingApiService.setHeader()) {
      return BillingApiService.post("api/items/check", items)
        .then(({ data }) => {
          if (data.success) {
            return Promise.resolve(data);
          }
        })
        .catch(({ response }) => {
          displayServerError(response, "Validating schedule items");
          return Promise.reject();
        });
    }
  }
}
