/* eslint-disable prettier/prettier */
const CodingActions = {

  CODING_TOKEN:                 "getCodingToken",
  LIST:                         "listAppointmentsForCoding",
  SEARCH_DIAGNOSES:             "searchDiagnosisCodes",
  SEARCH_ADMINISTRATION:        "searchAdministrationCodes",
  SHOW_DIAGNOSES:               "showSingleDiagnosisCodes",
  SHOW_ADMINISTRATION:               "showSingleAdministrationCodes",
  SEARCH_MORPHOLOGY :           "searchMorphologyCodes",
  CHECK_APPOINTMENTS_COMPLETE:  "CheckAppointmentsComplete",
  GENERATE_CODING_REPORT:       "GetGenerateCodingReportFile",

  APPOINTMENT_CODES : {
    LIST:   "ListAppointmentCodes",
    CREATE: "CreateAppointmentCode",
    DELETE: "DeleteAppointmentCode",
    UPDATE: "UpdateAppointmentCode",
  },

  APPOINTMENT_ADMINISTRATION_CODES : {
    LIST:   "ListAppointmentAdministrationCodes",
    CREATE: "CreateAppointmentAdministrationCode",
    DELETE: "DeleteAppointmentAdministrationCode",
    UPDATE: "UpdateAppointmentAdministrationCode",
  },

  APPOINTMENT_ADMINISTRATION_FORMS : {
    CREATE: "CreateAppointmentAdministrationForm",
    DELETE: "DeleteAppointmentAdministrationForm",
    UPDATE: "UpdateAppointmentAdministrationForm",
  }
};

const CodingMutations = {
  SET_LIST:   "setListAppointmentsForCoding",
  SET_SELECT: "setSelectedAppointmentsForCoding",

  SET_CODING_TOKEN:   "setCodingToken",
  PURGE_CODING_TOKEN: "deleteCodingToken",

};

export { CodingActions, CodingMutations };
