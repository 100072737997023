const AppointmentRoutes = [
  {
    path: "/appointments",
    name: "appointments",
    component: () => import("@/views/appointments/DashboardView.vue"),
    children: [
      {
        path: "dashboard",
        name: "booking-dashboard",
        component: () => import("@/views/appointments/Appointments.vue"),
      },
      {
        path: "unconfirmed-apts",
        name: "unconfirmed-apts",
        component: () => import("@/views/appointments/UnconfirmedApts.vue"),
      },
      {
        path: "waitlisted-apts",
        name: "waitlisted-apts",
        component: () => import("@/views/appointments/WaitlistedApts.vue"),
      },
      {
        path: "cancellation-list",
        name: "cancellation-list",
        component: () => import("@/views/appointments/CancellationList.vue"),
      },
      {
        path: "deallocate-appointments",
        name: "deallocate-appointments",
        component: () =>
          import("@/views/appointments/DeallocateAppointments.vue"),
      },
      {
        path: "checked-in-appointments",
        name: "checked-in-appointments",
        component: () => import("@/views/appointments/CheckedInApts.vue"),
      },
      {
        path: "appointment-list-all",
        name: "appointment-list-all",
        component: () => import("@/views/appointments/AppointmentListAll.vue"),
      },
    ],
  },
];

export default AppointmentRoutes;
