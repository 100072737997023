import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import ICorporates from "@/store/interfaces/ICorporates";
import { displayServerError } from "@/helpers/alertHelpers";

export interface CorporateInfo {
  corporatesData: Array<ICorporates>;
  corporatesSelectData: ICorporates;
}

@Module
export default class CorporateModule
  extends VuexModule
  implements CorporateInfo
{
  corporatesData = [] as Array<ICorporates>;
  corporatesSelectData = {} as ICorporates;

  /**
   * Get current Corporate List
   * @returns Corporate
   */
  get getCorporatesList(): Array<ICorporates> {
    return this.corporatesData;
  }

  /**
   * Get current selected Patient
   * @returns SelectedcorporatesData
   */
  get getSelectedCorporatesList(): ICorporates {
    return this.corporatesSelectData;
  }

  @Mutation
  [Mutations.SET_CORPORATES.LIST](corporatesData: Array<ICorporates>) {
    this.corporatesData = corporatesData;
  }

  @Action
  [Actions.CORPORATES.CREATE](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("corporates", { ...params })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CORPORATES.LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Fetching corporate list");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CORPORATES.LIST](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("corporates", { params: params })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CORPORATES.LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Fetching corporate list");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CORPORATES.SELECTED](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query(
        "appointments/" + params.appointment_id + "/corporate"
      )
        .then(({ data }) => {
          return data.data.corporate_id;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Fetching corporate list");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
