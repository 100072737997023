import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {
  displayServerError,
  displaySuccessModal,
} from "@/helpers/alertHelpers";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
export interface IOrgAdmin {
  id: number;
}

export interface OrgAdminInfo {
  orgAdminData: Array<IOrgAdmin>;
  orgAdminSelectData: IOrgAdmin;
}

@Module
export default class OrgAdminModule extends VuexModule implements OrgAdminInfo {
  orgAdminData = [] as Array<IOrgAdmin>;
  orgAdminSelectData = {} as IOrgAdmin;

  /**
   * Get current user object
   * @returns AdminList
   */
  get orgAdminList(): Array<IOrgAdmin> {
    return this.orgAdminData;
  }

  /**
   * Get current user object
   * @returns SelectedorgAdminData
   */
  get orgAdminSelected(): IOrgAdmin {
    return this.orgAdminSelectData;
  }

  @Mutation
  [Mutations.SET_ORG_ADMIN.LIST](orgAdminData) {
    this.orgAdminData = orgAdminData;
  }

  @Mutation
  [Mutations.SET_ORG_ADMIN.SELECT](data) {
    this.orgAdminSelectData = data;
  }

  @Action
  [Actions.ORG_ADMIN.ORGANIZATION.SETTINGS.UPDATE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("organizations/settings", data.submitData)
        .then(() => {
          return displaySuccessModal("Organisation settings updated");
        })
        .catch(({ response }) => {
          return displayServerError(response, "Creating a new organisation");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
