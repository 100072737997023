import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Mutations } from "@/store/enums/StoreEnums";
import {
  AppointmentActions,
  AppointmentMutations,
} from "@/store/enums/StoreAppointmentEnums";
import {
  displayServerError,
  displaySuccessToast,
  displaySuccessModal,
} from "@/helpers/alertHelpers";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import IAppointment from "../interfaces/IAppointment";

export interface AptInfo {
  aptData: Array<IAppointment>;
  aptSelectData: IAppointment;
  aptPreAdmissionOrgData: IAppointment;
  aptPreAdmissionValidateData: IAppointment;
  aptPreAdmissionValidateMsg: string;
  selectedSpecialistData;
  userAptList: Array<IAppointment>;
  aptUserSelectedData: IAppointment;
  aptDraftId: number;
  modalState?: boolean;
}

interface IShowParams {
  appointmentId: number;
  with: Array<string>;
}

@Module
export default class AppointmentModule extends VuexModule implements AptInfo {
  aptData = [] as Array<IAppointment>;
  aptSelectData = {} as IAppointment;
  aptPreAdmissionOrgData = {} as IAppointment;
  aptPreAdmissionValidateData = {} as IAppointment;
  aptPreAdmissionValidateMsg = "" as string;
  selectedSpecialistData = null;
  userAptList = [] as Array<IAppointment>;
  aptUserSelectedData = {} as IAppointment;
  aptDraftId = 0 as number;
  modalState = false as boolean;
  /**
   * Get current user object
   * @returns AdminList
   */
  get getAptList(): Array<IAppointment> {
    return this.aptData;
  }

  /**
   * Get current user object
   * @returns SelectedaptData
   */
  get getAptSelected(): IAppointment {
    return this.aptSelectData;
  }

  /**
   * Get current user object
   * @returns Selected specialist data
   */
  get getSelectedSpecialist() {
    return this.selectedSpecialistData;
  }

  /**
   * Get current user object
   * @returns SelectedaptData
   */
  get getAptPreAdmissionOrg(): IAppointment {
    return this.aptPreAdmissionOrgData;
  }

  /**
   * Get current user object
   * @returns SelectedaptData
   */
  get getAptPreAdmissionValidateData(): IAppointment {
    return this.aptPreAdmissionValidateData;
  }

  /**
   * Get current user object
   * @returns SelectedaptData
   */
  get getAptPreAdmissionValidateMsg(): string {
    return this.aptPreAdmissionValidateMsg;
  }

  /**
   * Get current user apt list
   * @returns SelectedaptData
   */
  get getUserAptList(): Array<IAppointment> {
    return this.userAptList;
  }

  /**
   * Get current selected appointment
   * @returns SelectedaptData
   */
  get getAptUserSelected(): IAppointment {
    return this.aptUserSelectedData;
  }
  get getDraftAptId(): number {
    return this.aptDraftId;
  }

  get getModalState(): boolean {
    return this.modalState;
  }

  @Mutation [AppointmentMutations.MODULE_STATE](data) {
    this.modalState = data;
  }

  @Mutation
  [AppointmentMutations.SET_APT.LIST](aptData) {
    this.aptData = aptData;
  }

  @Mutation
  [AppointmentMutations.SET_APT.SELECT](data) {
    this.aptSelectData = data;
  }

  @Mutation
  [AppointmentMutations.SET_APT.SELECT_SPECIALIST](data) {
    this.selectedSpecialistData = data;
  }

  @Mutation
  [AppointmentMutations.SET_APT.PRE_ADMISSION.ORG](data) {
    this.aptPreAdmissionOrgData = data;
  }

  @Mutation
  [AppointmentMutations.SET_APT.PRE_ADMISSION.VALIDATE.DATA](data) {
    this.aptPreAdmissionValidateData = data;
  }

  @Mutation
  [AppointmentMutations.SET_APT.PRE_ADMISSION.VALIDATE.MSG](data) {
    this.aptPreAdmissionValidateMsg = data;
  }

  @Mutation
  [AppointmentMutations.SET_APT.USER_APT.SELECT](data) {
    this.aptUserSelectedData = data;
  }

  @Mutation
  [AppointmentMutations.SET_APT.USER_APT.LIST](data) {
    this.userAptList = data;
  }

  @Mutation
  [AppointmentMutations.DRAFT.SET](data) {
    this.aptDraftId = data;
  }

  @Mutation
  [AppointmentMutations.SET_APT.REFERRAL.UPDATE](data) {
    this.aptSelectData.referral = data;
  }

  @Action
  [AppointmentActions.LIST](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("appointments", { params: params })
        .then(({ data }) => {
          this.context.commit(AppointmentMutations.SET_APT.LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing all appointments");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.SHOW](params: IShowParams | string) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      let appointmentId;
      let parameters = {} as object;

      if (typeof params == "object") {
        appointmentId = params.appointmentId;
        parameters = {
          with: params.with,
        };
      }
      if (typeof params == "string" || typeof params == "number") {
        appointmentId = params;
      }

      return ApiService.get("appointments/" + appointmentId, "", parameters)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Showing an appointment");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.CONFIRMATION_STATUS.UPDATE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update("appointments/confirmation-status", data.id, {
        confirmation_status: data.confirmed
          ? "CONFIRMED"
          : data.missed
          ? "MISSED"
          : "CANCELLED",
        confirmation_status_reason: data.reason,
      })
        .then(({ data }) => {
          displaySuccessToast("Appointment mark as confirmed!");
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Updating appointment confirmation status"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  async [AppointmentActions.CONFIRMATION_STATUS.CONFIRM](aptId) {
    try {
      const { data } = await ApiService.update(
        `appointments/confirmation-status/${aptId}/confirm`,
        "",
        {}
      );
      this.context.commit(AppointmentMutations.SET_APT.SELECT, data.data);
    } catch ({ response }) {
      return displayServerError(
        response,
        "Updating appointment confirmation status"
      );
    }
  }

  @Action
  [AppointmentActions.APPOINTMENT_INSTRUCTION.SHOW](aptId) {
    return ApiService.get(
      `appointments/procedure-instructions/${aptId}`,
      "",
      {}
    )
      .then(({ data }) => {
        this.context.commit(AppointmentMutations.SET_APT.SELECT, data.data);
      })
      .catch(({ response }) => {
        return displayServerError(
          response,
          "Getting appointment type instructions"
        );
      });
  }
  //
  @Action
  [AppointmentActions.APT.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("appointments", payload).catch(({ response }) => {
        return displayServerError(response, "Creating an appointment");
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.APT.DRAFT.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("appointments/draft", payload)
        .then(({ data }) => {
          this.context.commit(AppointmentMutations.DRAFT.SET, data.data.id);
          // this.context.commit(AppointmentMutations.SET_APT.SELECT, data.data);
        })
        .catch(({ response }) => {
          return displayServerError(response, "Creating an draft appointment");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.APT.DRAFT.DELETE](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete("appointments/draft/" + id)
        .then(({ data }) => {
          if (data.data == id) {
            this.context.commit(AppointmentMutations.DRAFT.SET, null);
          }
        })
        .catch(({ response }) => {
          return displayServerError(response, "Deleting an draft appointment");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action({ rawError: true })
  [AppointmentActions.APT.UPDATE](aptPayload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(
        "appointments",
        aptPayload.appointmentData.id,
        aptPayload
      )
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Updating an appointment");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.DATA.UPDATE](item) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update("appointments/data", item.id, item)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Updating appointment data");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.APT.CHECK_IN](appointmentId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(`appointments/check-in/${appointmentId}`, {})
        .then(() => {
          return displaySuccessToast("Patient checked in!");
        })
        .catch(({ response }) => {
          return displayServerError(response, "Checking in an appointment");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.APT.CHECK_OUT](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(`appointments/check-out/${data.id}`, {})
        .then(() => {
          return displaySuccessToast("Patient checked out!");
        })
        .catch(({ response }) => {
          return displayServerError(response, "Checking out an appointment");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.REFERRAL.UPDATE](payload: FormData) {
    return ApiService.post(
      "appointments/referral/" + payload.get("appointment_id"),
      payload
    )
      .then(({ data }) => {
        this.context.commit(
          AppointmentMutations.SET_APT.REFERRAL.UPDATE,
          data.data
        );
        return displaySuccessToast("Referral updated!");
      })
      .catch(({ response }) => {
        return displayServerError(response, "Updating a referral");
      });
  }

  @Action
  [AppointmentActions.DETAIL.UPDATE](data) {
    return ApiService.post(`appointments/${data.appointment_id}/detail`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        displayServerError(response, "Updating appointment details");
        return Promise.reject();
      });
  }
  @Action
  [AppointmentActions.APT.UPDATE_ATTENDANCE](data) {
    return ApiService.post(
      `appointments/${data.appointment_id}/attendance-status`,
      data
    )
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        displayServerError(response, "Updating appointment details");
        return Promise.reject();
      });
  }

  @Action
  [AppointmentActions.APT.BULK.LIST](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("appointments", { params: params })
        .then(({ data }) => {
          this.context.commit(
            AppointmentMutations.SET_APT.USER_APT.LIST,
            data.data
          );
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing all appointments");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.APT.BULK.UPDATE](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("appointments/bulk", params)
        .then(({ data }) => {
          this.context.dispatch(AppointmentActions.LIST);
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Update all appointments");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.RESEND_MESSAGE](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(`appointments/${params.id}/resend-message`)
        .then(({ data }) => {
          displaySuccessModal(data.message);
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Resend Confirm Message");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.APT.WAIT_LISTED.UPDATE](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`appointments/wait-listed/${params.id}`, params)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Update waitlisted of appointment"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
