import BillingApiService from "@/core/services/BillingApiService";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import IBillingResponse from "@/store/interfaces/IBillingResponse";
import { BillingActions } from "../enums/StoreBillingEnums";
import { displayServerError } from "@/helpers/alertHelpers";

export interface ClaimSubmissionResponse {
  status: string;
}

export interface ClaimResponse {
  errors: unknown;
  sourceClaimResponse: IBillingResponse<ClaimSubmissionResponse>;
}

@Module
export default class BillingValidationModule
  extends VuexModule
  implements ClaimResponse
{
  errors = {};
  sourceClaimResponse = {} as IBillingResponse<ClaimSubmissionResponse>;

  @Action
  async [BillingActions.SUBMIT_ESTIMATE](claim) {
    if (await BillingApiService.setHeader()) {
      return BillingApiService.post(
        `api/claims/estimate/submit/${claim.claim_method}`,
        claim
      )
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Submitting a Medicare claim estimate");
          return Promise.reject();
        });
    }
  }

  @Action
  async [BillingActions.SUBMIT_CLAIM](claim) {
    if (await BillingApiService.setHeader()) {
      return BillingApiService.post("api/claims/submit", claim)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Submitting a Medicare claim");
          return Promise.reject();
        });
    }
  }

  @Action
  async [BillingActions.WITHDRAW_CLAIM](claim) {
    if (await BillingApiService.setHeader()) {
      return BillingApiService.post("api/claims/withdraw", claim)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Withdrawing a Medicare claim");
          return Promise.reject();
        });
    }
  }

  @Action
  async [BillingActions.SUBMIT_ESTIMATED_REPAYMENT]({ endpoint, payload }) {
    if (await BillingApiService.setHeader()) {
      return BillingApiService.post(endpoint, payload)
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Fetching estimated repayment");
          return Promise.reject();
        });
    }
  }
}
