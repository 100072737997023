import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { AppointmentActions } from "@/store/enums/StoreAppointmentEnums";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import {
  displayServerError,
  displaySuccessToast,
} from "@/helpers/alertHelpers";

@Module
export default class AppointmentScheduleItemsModule extends VuexModule {
  @Action
  [AppointmentActions.SCHEDULE_ITEMS.LIST](appointmentId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("appointments/" + appointmentId + "/schedule-items")
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Listing all appointment schedule items"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.SCHEDULE_ITEMS.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("appointments/schedule-items", payload).catch(
        ({ response }) => {
          return displayServerError(
            response,
            "Creating a new appointment schedule item"
          );
        }
      );
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.SCHEDULE_ITEMS.DELETE](appointmentScheduleItem) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(
        "appointments/schedule-items/delete/" + appointmentScheduleItem.id,
        appointmentScheduleItem
      )
        .then(({ data }) => {
          const itemName = data.data.label_name;
          displaySuccessToast("Item " + itemName + " deleted");
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Deleting an appointment schedule item"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.SCHEDULE_ITEMS.UPDATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(
        "appointments/schedule-items/" + payload.id,
        payload
      ).catch(({ response }) => {
        return displayServerError(
          response,
          "Updating an appointment schedule item"
        );
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.SCHEDULE_ITEMS.RESET](appointmentId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(
        `appointments/${appointmentId}/schedule-items/reset`,
        {}
      )
        .then(() => {
          displaySuccessToast("Items reset");
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Resetting appointment schedule items"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
