const CodingRoutes = [
  {
    path: "/coding",
    name: "coding-dashboard",
    component: () =>
      import(
        "@/views/CodingBillingAdministration/ListAppointmentsForCoding.vue"
      ),
  },
  {
    path: "/coding-reports",
    name: "coding-reports",
    component: () =>
      import("@/views/CodingBillingAdministration/ManageCodingReports.vue"),
  },
];

export default CodingRoutes;
