import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { ElMessage } from "element-plus";
import router from "@/router";
import { showModal } from "@/helpers/modalHelpers";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import { destroyToken } from "@/core/services/JwtService";

export function displayServerError(response, action) {
  if (response.status === 401) return displaySignOutError();
  let html: string;
  if (!response.data) {
    html =
      "<h1>ERROR </h1>" +
      "<h4>" +
      action +
      "</h4>" +
      "<textarea style='height: 160px; width: 100%; padding: 10px; color: grey; font-size: small;' readonly>" +
      response +
      "</textarea>";
  } else {
    html =
      "<h1>ERROR " +
      response.status +
      "<h4>" +
      action +
      "</h4>" +
      "</h1><textarea style='height: 160px; width: 100%; padding: 10px; color: grey; font-size: small;' readonly>" +
      (response.data.data?.errors?.join("\n") || response.data.message) +
      "</textarea>";
  }
  Swal.fire({
    html: html,
    icon: "error",
    buttonsStyling: false,
    showCancelButton: false,
    confirmButtonText: "Close",
    customClass: {
      confirmButton: "btn btn-primary",
    },
  }).then((result) => {
    if (result.isDismissed) return;
  });
}

export function displayErrorInfo(response) {
  Swal.fire({
    text: response.data.message,
    icon: "error",
    buttonsStyling: false,
    confirmButtonText: "Ok, got it!",
    customClass: {
      confirmButton: "btn btn-primary",
    },
  });
}

export function displaySuccessToast(message) {
  ElMessage.success(message);
}

export function displayErrorToast(message) {
  ElMessage.error({
    message: message,
    duration: 2000,
  });
}

export function displaySuccessModal(text) {
  Swal.fire({
    text: text,
    icon: "success",
    buttonsStyling: false,
    confirmButtonText: "Ok, got it!",
    customClass: {
      confirmButton: "btn btn-primary",
    },
  });
}

export function displaySignOutError() {
  destroyToken();
  router.push({
    name: "sign-in",
  });
}
