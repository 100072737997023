<template>
  <el-tooltip tabindex="-1" :disabled="!info" placement="top" effect="light">
    <template #content>{{ info }}</template>
    <span :class="`cursor-pointer badge badge-${color}`">{{ label }}</span>
  </el-tooltip>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "status-badge",
  props: {
    label: { type: String, required: true },
    info: { type: String, required: false },
    color: { type: String, required: true },
  },
});
</script>
