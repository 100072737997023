<template>
  <div :class="classes">
    <el-tooltip :disabled="tooltip == null" placement="top-start" effect="dark">
      <template #content><span v-html="tooltip"></span></template>
      <span>
        <label v-if="label" :class="classString" style="color: grey"
          >{{ label }}
          <el-icon v-if="tooltip" class="mx-2 text-primary" :size="15">
            <info-filled />
          </el-icon> </label
      ></span>
    </el-tooltip>
    <el-form-item :prop="prop" :rules="rules">
      <slot></slot>
    </el-form-item>
  </div>
</template>

<script lang="ts">
import { InfoFilled } from "@element-plus/icons-vue";

export default {
  components: { InfoFilled },
  props: {
    label: { type: String },
    prop: { type: String },
    rules: { type: Array, required: false },
    tooltip: { type: String, required: false },
    classes: { type: String, required: false, default: "px-6" },
    required: { type: Boolean, default: false },
    fontSize: { type: Number, default: 6 },
    rowSpacing: { type: Number, default: 0 },
  },
  setup(props) {
    const classString = `fs-${props.fontSize} fw-bold mb-2 ${
      props.required ? "required" : null
    }`;

    const rowClass = `mb-${props.rowSpacing}`;

    return { classString, rowClass };
  },
};
</script>
