/* eslint-disable prettier/prettier */
const BillingActions = {
  SUBMIT_ESTIMATE: "getBillingClaimEstimate",
  SUBMIT_CLAIM: "postBillingClaim",
  WITHDRAW_CLAIM: "withdrawClaim",
  SUBMIT_ESTIMATED_REPAYMENT: "submitEstimatedRepayment",
};

const BillingMutations = {
  SET_ESTIMATE: "setBillingClaimEstimate",
};

export { BillingActions, BillingMutations };
