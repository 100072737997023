import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";
import CodingTokenService from "./CodingTokenService";
import store from "@/store";
import { CodingActions } from "@/store/enums/StoreCodingEnums";

/**
 * @description service to call HTTP request via Axios
 */
class CodingApiService {
  /**
   * @description property to share vue instance
   */
  public static axios: AxiosInstance;
  public static clientSecret: string;
  public static clientId: string;

  /**
   * @description initialize vue axios
   */
  public static init() {
    CodingApiService.axios = axios.create({
      baseURL: import.meta.env.VITE_APP_CODING_API_URL,
    });

    CodingApiService.clientSecret =
      import.meta.env.VITE_APP_CODING_CLIENT_SECRET;
    CodingApiService.clientId = import.meta.env.VITE_APP_CODING_CLIENT_ID;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): Promise<boolean> {
    CodingTokenService.destroyToken();
    return store
      .dispatch(CodingActions.CODING_TOKEN)
      .then(() => {
        CodingApiService.axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${CodingTokenService.getCodingToken()}`;
        CodingApiService.axios.defaults.headers.common["Accept"] =
          "application/json";
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return CodingApiService.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string,
    params = {} as object
  ): Promise<AxiosResponse> {
    return CodingApiService.axios.get(`${resource}/${slug}`, {
      params: params,
    });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params,
    config?
  ): Promise<AxiosResponse> {
    return CodingApiService.axios.post(`${resource}`, params, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params
  ): Promise<AxiosResponse> {
    return CodingApiService.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params): Promise<AxiosResponse> {
    return CodingApiService.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return CodingApiService.axios.delete(resource);
  }
}

export default CodingApiService;
