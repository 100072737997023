import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";
/**
 * @description service to call HTTP request via Axios
 */
class HealthLinkApiService {
  /**
   * @description property to share vue instance
   */
  public static axios: AxiosInstance;
  public static apiKey: string;
  public static providerUrl: string;

  /**
   * @description initialize vue axios
   */
  public static init() {
    HealthLinkApiService.axios = axios.create({
      baseURL: import.meta.env.VITE_APP_HEALTH_LINK_API_URL,
    });
    HealthLinkApiService.apiKey = import.meta.env.VITE_APP_HEALTH_LINK_API_KEY;
    HealthLinkApiService.providerUrl =
      import.meta.env.VITE_APP_HEALTH_LINK_PROVIDER_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    HealthLinkApiService.axios.defaults.headers.common["x-api-key"] =
      HealthLinkApiService.apiKey;
    HealthLinkApiService.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return HealthLinkApiService.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string,
    params = {} as object
  ): Promise<AxiosResponse> {
    return HealthLinkApiService.axios.get(`${resource}/${slug}`, {
      params: params,
    });
  }
}

export default HealthLinkApiService;
