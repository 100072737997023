import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {
  AppointmentActions,
  AppointmentMutations,
} from "@/store/enums/StoreAppointmentEnums";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { displayServerError } from "@/helpers/alertHelpers";
import IClaim from "../interfaces/IClaim";

@Module
export default class AppointmentClaimModule extends VuexModule {
  //public claimsList: any[] = [];
  claimsList = [] as Array<IClaim>;

  get claimsListData(): Array<IClaim> {
    return this.claimsList;
  }

  @Mutation
  [AppointmentMutations.SET_CLAIM.LIST](claimsList) {
    this.claimsList = claimsList;
  }

  @Action
  [AppointmentActions.CLAIM_ESTIMATE.CREATE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(
        `/appointments/${data.appointment_id}/estimates`,
        data
      )
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Create an appointment estimate");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.CLAIM_ESTIMATE.UPDATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(
        `appointments/${payload.appointment_id}/estimates/${payload.estimate_id}`,
        payload
      )
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(
            response,
            "Updating an appointment estimate"
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.CLAIM.LIST](params) {
    return ApiService.query("claims", {
      params: params,
    })
      .then(({ data }) => {
        this.context.commit(AppointmentMutations.SET_CLAIM.LIST, data.data);
        return data.data;
      })
      .catch(({ response }) => {
        return displayServerError(response, "Listing appointment claims");
      });
  }

  @Action
  [AppointmentActions.CLAIM.CREATE](appointment_id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`/appointments/${appointment_id}/claims`, {})
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Create an appointment claim");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [AppointmentActions.CLAIM.UPDATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(
        `appointments/${payload.appointment_id}/claims/${payload.id}`,
        {
          status: payload.status,
          transaction_id: payload.transaction_id,
        }
      )
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Updating an appointment claim");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
