import BillingApiService from "@/core/services/BillingApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import IPhaItem from "@/store/interfaces/IPhaItem";

export interface PhaItemResponse {
  success: boolean;
  data: PhaItemDataResponse;
}

export interface PhaItemDataResponse {
  total: number;
  items: Array<IPhaItem>;
}

export interface PhaItemInfo {
  errors: unknown;
  phaItemResponse: PhaItemResponse;
}

@Module
export default class PhaModule extends VuexModule implements PhaItemInfo {
  errors = {};
  phaItemResponse = {} as PhaItemResponse;

  /**
   * Get PHA item response
   * @returns PhaItemResponse
   */
  get phaItems(): PhaItemDataResponse {
    return this.phaItemResponse.data;
  }

  @Mutation
  [Mutations.SET_PHA.LIST](data) {
    this.phaItemResponse = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error, status: "failed" };
  }

  @Action
  async [Actions.PHA.LIST](search) {
    if (await BillingApiService.setHeader()) {
      return BillingApiService.get("api/items/pha", "", search)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_PHA.LIST, data);
          } else {
            throw data;
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          return Promise.reject();
        });
    }
  }
}
