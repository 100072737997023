import BillingApiService from "@/core/services/BillingApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import IMbsItem from "@/store/interfaces/IMbsItem";

export interface MbsItemResponse {
  success: boolean;
  data: MbsItemDataResponse;
}

export interface MbsItemDataResponse {
  total: number;
  items: Array<IMbsItem>;
}

export interface MbsItemInfo {
  errors: unknown;
  mbsItemResponse: MbsItemResponse;
}

@Module
export default class MbsModule extends VuexModule implements MbsItemInfo {
  errors = {};
  mbsItemResponse = {} as MbsItemResponse;

  /**
   * Get MBS item response
   * @returns MbsItemResponse
   */
  get mbsItems(): MbsItemDataResponse {
    return this.mbsItemResponse.data;
  }

  @Mutation
  [Mutations.SET_MBS.LIST](data) {
    this.mbsItemResponse = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error, status: "failed" };
  }

  @Action
  async [Actions.MBS.LIST](search) {
    if (await BillingApiService.setHeader()) {
      return BillingApiService.get("api/items/mbs", "", search)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_MBS.LIST, data);
          } else {
            throw data;
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.data);
          return Promise.reject();
        });
    }
  }
}
