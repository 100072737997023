const HRMRoutes = [
  {
    path: "/employees",
    name: "employees",
    component: () => import("@/views/employees/Employee.vue"),
  },
  {
    path: "/employees/:orgId",
    name: "employees-admin",
    component: () => import("@/views/employees/Employee.vue"),
  },
  {
    path: "/employees/:orgId/create",
    name: "employees-create-admin",
    component: () => import("@/views/employees/EditEmployee.vue"),
  },
  {
    path: "/employees/:orgId/edit/:id",
    name: "employees-edit-admin",
    component: () => import("@/views/employees/EditEmployee.vue"),
  },
  {
    path: "/employees/create",
    name: "employees-create",
    component: () => import("@/views/employees/EditEmployee.vue"),
  },
  {
    path: "/employees/edit/:id",
    name: "employees-edit",
    component: () => import("@/views/employees/EditEmployee.vue"),
  },
  {
    path: "/hrm/weekly-schedule",
    name: "hrm-weekly-schedule",
    component: () => import("@/views/HRM/WeeklySchedule.vue"),
  },
  {
    path: "/hrm/weekly-availability-template",
    name: "hrm-weekly-availability-template",
    component: () => import("@/views/HRM/WeeklyScheduleTemplate.vue"),
  },
  {
    path: "/hrm/bulletin-board",
    name: "bulletin-board",
    component: () => import("@/views/HRM/BulletinBoard.vue"),
  },
  {
    path: "/hrm/bulletin-manage",
    name: "bulletin-manage",
    component: () => import("@/views/HRM/BulletinManage.vue"),
  },
  {
    path: "/hrm/leave-management",
    name: "leave-management",
    component: () => import("@/views/HRM/EmployeeLeave.vue"),
  },
  {
    path: "/hrm",
    name: "user-schedule",
    component: () => import("@/views/HRM/ScheduleView.vue"),
    children: [
      {
        path: "my-schedule",
        name: "my-schedule",
        component: () => import("@/views/HRM/MySchedule.vue"),
      },
      {
        path: "my-availability",
        name: "my-availability",
        component: () => import("@/views/HRM/MyAvailability.vue"),
      },
      {
        path: "settings",
        name: "my-hrm-settings",
        component: () => import("@/views/HRM/Settings.vue"),
      },
    ],
  },
];

export default HRMRoutes;
