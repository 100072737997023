const PatientActions = {
  LIST: "listPatients",
  LIST_NAMES: "listPatientNames",
  CREATE: "createPatients",
  DELETE: "deletePatients",
  UPDATE: "updatePatients",
  VIEW: "viewPatient",
  APPOINTMENTS: "patientsAppointments",
  DOCUMENTS: {
    LIST: "listPatientsDocuments",
    CREATE: "createPatientsDocument",
    UPDATE: "updatePatientsDocument",
    SEND_VIA_EMAIL: "sendPatientsDocumentViaEmail",
    SEND_VIA_FAX: "sendPatientsDocumentViaFax",
    SEND_VIA_HEALTH_LINK: "sendPatientsDocumentViaHealthLink",
    VIEW: "viewPatientDocument",
    AUDIO: {
      CREATE: "createPatientsDocumentAudio",
    },
    HISTORY: {
      LIST: "patientDocumentHistoryList",
    },
  },
  RECALL: {
    LIST: "listPatientsRecall",
    CREATE: "createPatientsRecall",
    UPDATE: "updatePatientsRecall",
  },
  ALERT: {
    CREATE: "createPatientAlert",
    UPDATE: "updatePatientAlert",
  },
  ALLERGY: {
    ADD: "addPatientAllergy",
    UPDATE: "updatePatientAllergy",
    DELETE: "deletePatientAllergy",
  },
  COMMON_ALLERGY: {
    LIST: "listCommonAllergies",
    ADD: "addCommonAllergy",
  },
  MEDICATION: {
    ADD: "addPatientMedication",
    UPDATE: "updatePatientMedication",
    DELETE: "deletePatientMedication",
  },
  GENERAL_PRACTITIONER: {
    ADD: "addPatientGeneralPractitioner",
    UPDATE: "updatePatientGeneralPractitioner",
  },
  CLAIM_SOURCE: {
    VALIDATE_MEDICARE: "validatePatientsBillingMedicare",
    VALIDATE_CONCESSION: "validatePatientsBillingConcession",
    VALIDATE_HEALTH_FUND: "validatePatientsBillingHealthFund",
    VALIDATE_DVA: "validatePatientsBillingDva",
    ADD: "addPatientsBilling",
    UPDATE: "updatePatientsBilling",
    DELETE: "deletePatientsBilling",
  },
  ALSO_KNOWN_AS: {
    CREATE: "createPatientAlsoKnownAs",
    UPDATE: "updatePatientAlsoKnownAs",
    BULK: "bulkPatientAlsoKnownAs",
    DELETE: "deletePatientAlsoKnownAs",
  },
  NOTIFICATION_LOG: {
    LIST: "NotificationLogList",
  },
  MERGE: {
    CREATE: "mergePatients",
  },
  LOG: {
    VIEW: "viewPatientActionLog",
  },
  REPORT_DATA: {
    LIST: "PatientReportDataList",
  },
};

const PatientMutations = {
  SET_PATIENT: {
    LIST: "setPatientsList",
    SELECT: "setSelectPatient",
    APPOINTMENTS: "setPatientAppointments",
    DOCUMENTS: {
      LIST: "setPatientDocumentList",
      HISTORY: {
        LIST: "setPatientDocumentHistoryList",
      },
    },
  },

  SET_PATIENT_RECALL: {
    LIST: "setPatientsRecallList",
    SELECT: "setSelectPatientRecall",
  },

  CLAIM_SOURCE: {
    SET_VALIDATION: "setClaimSourceValidation",
  },

  SET_COMMON_ALLERGY: {
    LIST: "setListCommonAllergies",
    ADD: "setCommonAllergyAdd",
  },

  SET_NOTIFICATION_LOG: {
    LIST: "setListOfNotificationLogRecords",
    SELECT: "setNotificationLogRecord",
  },
  LOG: {
    SET: "setPatientActionLog",
  },
  REPORT_DATA: {
    SET: "setPatientActionLog",
  },
};

export { PatientActions, PatientMutations };
