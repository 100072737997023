/* eslint-disable prettier/prettier */
const HealthLinkActions = {

    LIST: "listHealthLinkPractitioners",
    SELECT: "SelectHealthLinkPractitioner",
};

const HealthLinkMutations = {
    SET_LIST: "SetListHealthLinkPractitioners",
    SET_SELECT: "SetHealthLinkPractitioner",
};

export { HealthLinkActions, HealthLinkMutations };
