<template>
  <div class="el-input__wrapper el-input el-input--large w-100">
    <input
      class="currency-input el-input__inner"
      ref="inputRef"
      type="text"
      :placeholder="placeholder"
    />
  </div>
</template>

<script lang="ts">
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: [Number, String], // Vue 2: value
    placeholder: [String],
    currency: { type: String, required: false, default: "USD" },
  },
  setup(props) {
    const { inputRef } = useCurrencyInput({ currency: props.currency });
    return { inputRef };
  },
};
</script>
