import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {
  displayServerError,
  displaySuccessToast,
} from "@/helpers/alertHelpers";
import { Actions } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ScheduleFeeManageModule extends VuexModule {
  @Action
  [Actions.SCHEDULE_FEE_MANAGE.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("schedule-items", payload)
        .then((data) => {
          displaySuccessToast("Fee Schedule Management Created Successfully");
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Could not create schedule item.");
        });
    }
  }

  @Action
  [Actions.SCHEDULE_FEE_MANAGE.UPDATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(
        `/schedule-items/${payload.schedule_item_id}/fee-manage`,
        payload.id,
        payload
      )
        .then((data) => {
          displaySuccessToast("Fee Schedule Management Updated Successfully");
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Could not update schedule item.");
        });
    }
  }

  @Action
  [Actions.SCHEDULE_FEE_MANAGE.DELETE](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete("schedule-items/" + id)
        .then(({ data }) => {
          displaySuccessToast("Fee Schedule Deleted Successfully");
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError(response, "Could not delete schedule item.");
        });
    }
  }
}
