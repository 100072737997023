<template>
  <div
    class="modal fade"
    :id="'modal_' + modalId"
    tabindex="-1"
    aria-hidden="true"
    ref="bModalRef"
    :data-bs-backdrop="isStatic ? 'static' : 'none'"
  >
    <div
      :class="'modal-dialog modal-dialog-centered'"
      :style="'max-width: ' + (width ? width : '850px') + '!important'"
    >
      <div
        class="modal-content"
        :style="`${height ? 'height: ' + height : ''}`"
      >
        <div
          class="modal-header d-flex flex-row"
          :id="'kt_modal_' + modalId + '_header'"
        >
          <div class="w-100" v-if="hasHeader">
            <slot name="header"></slot>
          </div>
          <HeadingText v-else :text="title" />

          <div class="d-flex flex-row justify-content-end">
            <slot name="header-actions"></slot>
            <div
              :id="'kt_modal_' + modalId + '_close'"
              data-bs-dismiss="modal"
              class="btn btn-icon btn-sm btn-active-icon-primary"
              @click="onModalClose ? onModalClose() : null"
            >
              <span class="svg-icon svg-icon-1">
                <InlineSVG icon="cross" />
              </span>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div v-if="hasFooter" class="modal-footer flex-end">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.modal-body {
  overflow-y: scroll;
}
</style>
<script lang="ts">
import { ref, onMounted } from "vue";

export default {
  props: {
    title: { type: String, required: true },
    modalId: { type: String, required: true },
    modalRef: { required: false },
    updateRef: { type: Function, required: false },
    isStatic: { type: Boolean, required: false, default: true },
    onModalClose: { type: Function, required: false },
    width: { type: String, required: false },
    height: { type: String, required: false },
  },
  setup(props, { slots }) {
    const bModalRef = ref(null);

    const hasFooter = ref(false);
    if (slots.footer && slots.footer().length) {
      hasFooter.value = true;
    }

    const hasHeader = ref(false);
    if (slots.header && slots.header().length) {
      hasHeader.value = true;
    }

    onMounted(() => {
      props.updateRef && true && props.updateRef(bModalRef.value);
    });

    return {
      hasHeader,
      hasFooter,
      bModalRef,
    };
  },
};
</script>
