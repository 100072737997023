import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  displayServerError,
  displaySuccessToast,
} from "@/helpers/alertHelpers";
import IUserSettings from "../interfaces/IUserSettings";

@Module
export default class UserSettingsModule extends VuexModule {
  settings = {} as IUserSettings;

  /**
   * Get current user object
   * @returns profileSelectedData
   */
  get userSettings(): IUserSettings {
    return this.settings;
  }

  @Mutation
  [Mutations.USER_SETTINGS.SET](data) {
    this.settings = data;
  }

  @Action
  [Actions.USER_SETTINGS.VIEW]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("users/settings")
        .then(({ data }) => {
          this.context.commit(Mutations.USER_SETTINGS.SET, data.data);
        })
        .catch(({ response }) => {
          return displayServerError(response, "viewing user settings");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.USER_SETTINGS.UPDATE](item) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("users/settings/" + item.user_id, item).catch(
        ({ response }) => {
          return displayServerError(response, "Updating User settings");
        }
      );
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.USER_SETTINGS.RESTRICTION.UPDATE](item) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put("users/restriction/" + item.employee_id, item)
        .then(() => {
          return displaySuccessToast("Employee restriction Updated");
        })
        .catch(({ response }) => {
          return displayServerError(response, "Updating Employee settings");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
