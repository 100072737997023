import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ITimeSchedule } from "@/store/modules/BookingModule";

export interface ISpecialist {
  id: number;
  name: string;
  title: string;
  full_name: string;
  first_name: string;
  last_name: string;
  label: string;
  updated_at: string;
  created_at: string;
  schedule_timeslots: Array<ITimeSchedule>;
  hrm_work_schedule: Array<ITimeSchedule>;
}

export interface SpecalistInfo {
  specialistsData: Array<ISpecialist>;
  searchSpecialistsData: Array<ISpecialist>;
  specialistsSelectData: ISpecialist;
}

export interface Slot {
  id: number;
  user_id: number;
  start_time: string;
  end_time: string;
  week_day: string;
  relatedLeave?;
}

export interface Option {
  value: number;
  label: string;
}

export interface BusinessHour {
  startTime: string;
  endTime: string;
  daysOfWeek: number[];
}

export interface SpecialistListItem {
  value: number;
  label: string;
  id: number;
  title: string;
  businessHours: BusinessHour[];
}

export interface SpecialistListQueryParams {
  role_id: number;
  is_enabled: number;
  date: string | null;
  employee_leaves: number;
  schedule_time_slots?: number;
}

@Module
export default class SpecialistModule
  extends VuexModule
  implements SpecalistInfo
{
  specialistsData = [] as Array<ISpecialist>;
  searchSpecialistsData = [] as Array<ISpecialist>;
  specialistsSelectData = {} as ISpecialist;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private specialistDate: any;

  /**
   * Get current user object
   * @returns SpecialistList
   */
  get getSpecialistList(): Array<ISpecialist> {
    return this.specialistsData;
  }

  /**
   * Get current user object
   * @returns SpecialistList
   */
  get getSpecialistSelected(): ISpecialist {
    return this.specialistsSelectData;
  }

  /**
   * Get current searched specialist list object
   * @returns SpecialistList
   */
  get getSearchSpecialistList(): Array<ISpecialist> {
    return this.searchSpecialistsData;
  }

  @Mutation
  [Mutations.SET_SPECIALIST.LIST](specialistsData) {
    this.specialistsData = specialistsData;
  }

  @Mutation
  [Mutations.SET_SPECIALIST.SELECT](data) {
    this.specialistsSelectData = data;
  }

  @Mutation
  [Mutations.SET_SPECIALIST.SEARCH.SEARCH_LIST](specialistsData) {
    this.searchSpecialistsData = specialistsData;
  }

  @Action
  [Actions.SPECIALIST.LIST](payload: Partial<SpecialistListQueryParams> = {}) {
    const { date, schedule_time_slots } = payload;
    const queryParams: SpecialistListQueryParams = {
      role_id: 5,
      is_enabled: 1,
      date: date || null,
      employee_leaves: 1,
    };

    if (schedule_time_slots === 1) {
      queryParams.schedule_time_slots = schedule_time_slots;
    }

    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("users", {
        params: queryParams,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SPECIALIST.LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          console.log(response.data.error);
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SPECIALIST.CREATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("specialists", payload)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          console.log(response.data.error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SPECIALIST.UPDATE](item) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.update("specialists", item.id, item)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          console.log(response.data.error);
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SPECIALIST.DELETE](id) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.delete("specialists/" + id)
        .then(({ data }) => {
          return data.data;
        })
        .catch(({ response }) => {
          console.log(response.data.error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SPECIALIST.SEARCH.LIST](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("users", "", data)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SPECIALIST.SEARCH.SEARCH_LIST,
            data.data
          );
          return data.data;
        })
        .catch(({ response }) => {
          console.log(response.data.error);
          // this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
