import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Mutations } from "@/store/enums/StoreEnums";
import {
  PatientActions,
  PatientMutations,
} from "@/store/enums/StorePatientEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import {
  IPatientActionLog,
  IPatientActionLogResponse,
} from "@/store/interfaces/IPatientActionLog";
import { displayServerError } from "@/helpers/alertHelpers";
import IPatient from "@/store/interfaces/IPatient";

@Module
export default class PatientActionLogModule extends VuexModule {
  actionLogData = {} as IPatientActionLogResponse;

  get actionLogList(): Array<IPatientActionLog[]> {
    return this.actionLogData.logs;
  }

  get actionLogPatient(): IPatient {
    return <IPatient>this.actionLogData.patient;
  }

  @Mutation
  [PatientMutations.LOG.SET](data) {
    this.actionLogData = data;
  }

  @Action
  [PatientActions.LOG.VIEW](params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("patients/action-log/", { params: params })
        .then(({ data }) => {
          this.context.commit(PatientMutations.LOG.SET, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          displayServerError("Display Patient Action logs", response);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
