<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <button
    :class="` btn btn-${large ? 'lg' : 'sm'} ${
      noBorder ? '' : 'border border-solid'
    } border-${color} btn-${light ? 'light-' : ''}${color}  px-${
      label ? '4' : '4'
    }`"
    :type="type"
    :disabled="disabled"
    @click="routeToLink"
  >
    <el-tooltip
      :disabled="tooltip == null"
      :content="tooltip"
      placement="bottom"
      effect="light"
    >
      <span>
        <span
          :class="`d-flex align-items-center
          ${reverseIcon ? 'flex-row-reverse' : 'flex-row'}
          ${alignLeft ? 'justify-content-start ' : 'justify-content-center'}
          ${
            iconPath && label && large
              ? 'gap-2'
              : iconPath && label
              ? 'gap-1'
              : ''
          } `"
          v-if="!loading"
        >
          <span
            v-if="iconPath"
            :class="`svg-icon svg-icon-${large ? '2' : '1'}x m-0`"
          >
            <inline-svg :src="iconPath" />
          </span>
          <span v-if="label">{{ label }}</span>
        </span>
        <span v-if="loading">
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
          {{ loadingLabel }}
        </span>
      </span>
    </el-tooltip>
  </button>
</template>

<script lang="ts">
import { ButtonHTMLAttributes, PropType } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    label: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
    color: { type: String, required: false, default: "primary" },
    type: {
      type: String as PropType<ButtonHTMLAttributes["type"]>,
      required: false,
      default: "button",
    },
    loading: { type: Boolean, required: false, default: false },
    loadingLabel: { type: String, default: "Loading..." },
    iconPath: { type: String, required: false },
    tooltip: { type: String, default: null },
    large: { type: Boolean, required: false, default: false },
    light: { type: Boolean, required: false, default: false },
    noBorder: { type: Boolean, required: false, default: false },
    alignLeft: { type: Boolean, required: false, default: false },
    reverseIcon: { type: Boolean, required: false, default: false },
    routeName: { type: String, default: null },
  },
  setup(props) {
    const router = useRouter();
    const routeToLink = () => {
      if (props.routeName) {
        router.push({ name: props.routeName });
      }
    };
    return { routeToLink };
  },
};
</script>
