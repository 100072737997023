import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import {
  displayServerError,
  displaySuccessToast,
} from "@/helpers/alertHelpers";

@Module
export default class ImportModule extends VuexModule {
  impData = [];

  get getImportList() {
    return this.impData;
  }

  @Mutation
  [Mutations.SET_IMPORTED_PATIENT.LIST](impData) {
    this.impData = impData;
  }

  @Action
  [Actions.IMPORT.CREATE](json) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`/import-data/patients`, json)
        .then(() => {
          return displaySuccessToast("Patient Imported");
        })
        .catch(({ response }) => {
          return displayServerError(response, "Posting Json");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.IMPORT.LIST]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("/import-data/patients")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_IMPORTED_PATIENT.LIST, data.data);
          return data.data;
        })
        .catch(({ response }) => {
          return displayServerError(response, "Listing all imported patients");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
