import IDoctorAddressBook from "@/store/interfaces/IDoctorAddressBook";
import { IHealthLink } from "@/store/interfaces/IHealthLinkInterfaces";
import { IAddress } from "@/assets/ts/components/_CreateAppointmentComponent";

export function convertToDoctorAddressBook(data: IHealthLink) {
  const doctorAddressBook = {
    id: -1,
    first_name: "",
    full_name: "",
    healthlink_edi: "",
    last_name: "",
    organization_id: -1,
    practice_address: {} as IAddress,
    practice_email: "",
    practice_fax: "",
    practice_name: "",
    practice_phone: "",
    preferred_communication_method: "1",
    provider_no: "",
    title: "",
  } as IDoctorAddressBook;

  data.entry.forEach((entry) => {
    if (entry.resource.resourceType === "PractitionerRole") {
      doctorAddressBook.provider_no = entry.resource.identifier.filter(
        (idt) => idt.type.text == "Medicare Provider Number"
      )[0].value;
      doctorAddressBook.practice_name = entry.resource.organization.display;
    } else if (entry.resource.resourceType === "Organization") {
      doctorAddressBook.practice_address = getHLAddress(entry.resource.address);
      const practitionerAddress = getCommunicationMethods(
        entry.resource.telecom
      );
      doctorAddressBook.practice_email = practitionerAddress.email.value;
      doctorAddressBook.practice_fax = practitionerAddress.fax.value;
      doctorAddressBook.practice_phone = practitionerAddress.phone.value;
    } else if (entry.resource.resourceType === "Practitioner") {
      if (entry.resource.name && entry.resource.name.length > 0) {
        doctorAddressBook.full_name = entry.resource.name[0].text;
        doctorAddressBook.last_name = entry.resource.name[0].family;
        doctorAddressBook.first_name = entry.resource.name[0].given.join(" ");
        if (entry.resource.name[0].prefix.length > 0)
          doctorAddressBook.title = entry.resource.name[0].prefix[0];
      }
    }
  });
  return doctorAddressBook;
}

export function getHLAddress(addresses) {
  const address = {
    full_address: "",
    street_number: "",
    street_name: "",
    suburb: "",
    country: "",
    state: "",
    postal_code: "",
  } as IAddress;

  const physicalAddress = addresses.filter(
    (address) => address.type == "physical"
  );

  if (physicalAddress.length > 0) {
    address.country = physicalAddress[0].country;
    address.suburb = physicalAddress[0].city;
    address.postal_code = physicalAddress[0].postalCode;
    address.state = physicalAddress[0].state;
    address.full_address = physicalAddress[0].text;
    const testNumber = getStreetNameNumber(physicalAddress[0].line);
    address.street_name = testNumber.name;
    address.street_number = testNumber.number;
  }

  function getStreetNameNumber(lines) {
    const street = {
      name: "",
      number: "",
    };
    if (lines.length > 0) {
      lines.forEach((line, index) => {
        if (index == 0 && lines[0].split(" ").length > 0) {
          const numbers = lines[0].split(" ");
          street.number = numbers[0];
          street.name += numbers.slice(1).join(" ");
        } else {
          street.name += line;
        }
      });
    }
    return street;
  }
  return address;
}

export function getCommunicationMethods(telecom) {
  const data = {
    email: {
      value: "",
      rank: 10,
    },
    fax: {
      value: "",
      rank: 10,
    },
    phone: {
      value: "",
      rank: 10,
    },
  };
  if (!telecom) return data;

  for (const dataKey in data) {
    telecom.forEach((item) => {
      if (item.system == dataKey && parseInt(item.rank) < data[dataKey].rank) {
        data[dataKey].value = formatChange(item.value);
      }
    });
  }

  function formatChange(number) {
    const numberAsArray = number.split("");
    if (
      numberAsArray.length !== 10 &&
      numberAsArray.slice(0, 3).join("") == "+61"
    ) {
      return (
        "0" +
        numberAsArray
          .slice(3)
          .filter((num) => num != " ")
          .join("")
      );
    }
    return number;
  }
  return data;
}

export function validateProviderNumber(
  rule: any,
  value: any,
  callback: any,
  doctor_name: string
) {
  setTimeout(() => {
    if (value == "" && doctor_name == "")
      callback(new Error("Please input practitioner name or provider ID"));
    if (value == "") callback();
    if (value && hasWhiteSpace(value))
      callback(new Error("Please input valid provider ID"));
    if (value && value.toString().length !== 8) {
      callback(new Error("Provider number should have only 8 digits"));
    }
    const valArray = value.split("");
    if (locationCharacter(valArray.pop()))
      callback(new Error("Please input valid provider ID"));
    valArray.pop();
    if (!Number.isInteger(Number(valArray.join(""))))
      callback(new Error("Please input valid provider ID"));
    else {
      callback();
    }
  }, 300);

  function locationCharacter(char) {
    return "YXWTLKJHFBA".indexOf(char) < 0;
  }

  function hasWhiteSpace(s) {
    return s.toString().indexOf(" ") >= 0;
  }
}

export function checkExistingDoctors(doctorAddressBooks, provider_no) {
  return doctorAddressBooks.filter(
    (doc) => doc.provider_no.toLowerCase() === provider_no.toLowerCase()
  );
}

export function createDoctorAddressBookFilterByProviderNo(term) {
  const keyword = term.toString();
  return (doctorAddressBook) => {
    const full_name_pos = doctorAddressBook.provider_no
      .toLowerCase()
      .indexOf(keyword.toLowerCase());
    return full_name_pos !== -1;
  };
}

export function entryToProviderNo(entry) {
  const provider = entry.resource.identifier.filter(
    (idt) => idt.type.text == "Medicare Provider Number"
  );
  if (provider.length !== 0) return provider[0].value;
  return "";
}
