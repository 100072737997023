/* eslint-disable prettier/prettier */
const AppointmentActions = {
  LIST: "listAppointment", // (get)    'appointments'
  LIST_BY_KEYWORD: "listAppointmentByKeyword", // (get)    'appointments/keyword/{keyword}'
  SHOW: "showAppointment", // (get)    'appointments/{appointment}'
  REFERRAL: {
    UPDATE: "updateAppointmentReferral", // (put)    'appointments/referral/{appointment}'
    VIEW: "viewAppointmentReferral", // (post)   'appointments/referral/file'
  },
  APPOINTMENT_TYPES: {
    LIST: "listAptTypes", //  (get)    '/appointment-types'
    CREATE: "createAptTypes", //  (post)   '/appointment-types'
    UPDATE: "updateAptTypes", //  (put)    '/appointment-types/{appointmentType}'
    DELETE: "deleteAptTypes", //  (delete) '/appointment-types/{appointmentType}'
  },
  CONFIRMATION_STATUS: {
    UPDATE: "updateConfirmationApt", //  (put) '/appointments/confirmation-status/{appointment}'
    CONFIRM: "confirmAppointmentPatient", //  (put) /appointments/confirmation-status/{appointment}/confirm'
  },
  DATA: {
    UPDATE: "UpdateAppointmentData", //  (put) '/appointments/data/{appointment}'
  },
  APPOINTMENT_INSTRUCTION: {
    SHOW: "GetAppointmentInstructions", //  (get) 'appointments/procedure-instructions/{appointment:token}'
  },
  PRE_ADMISSION: {
    VIEW: "viewPreAdmissionForm", // (post)   'appointment/procedure-approvals/pdf/{appointment}'
    ORGANIZATION: "listAptPreAdmissionOrg",
    VALIDATE: "validateAptPreAdmission",
    STORE: "createAptPreAdmission",
  },
  MONTH_AVAILABILITIES: "showMonthAvailabilities",
  DETAIL: {
    UPDATE: "updateAppointmentDetail",
  },
  CLAIM: {
    LIST: "getAllAppointmentClaims",
    CREATE: "createAppointmentClaim",
    UPDATE: "updateAppointmentClaim",
  },
  CLAIM_ESTIMATE: {
    CREATE: "createAppointmentClaimEstimate",
    UPDATE: "updateAppointmentClaimEstimate",
  },
  // These are slowly being moved to the above
  APT: {
    CREATE: "createApt",
    DELETE: "deleteApt",
    UPDATE: "updateApt",
    CHECK_IN: "checkInApt",
    CHECK_OUT: "checkOutApt",
    UPDATE_ATTENDANCE: "updateAttendance",
    DRAFT: {
      CREATE: "createDraftApt",
      DELETE: "deleteDraftApt",
    },
    BULK: {
      LIST: "listApt",
      UPDATE: "updateApts",
    },
    WAIT_LISTED: {
      UPDATE: "updateWaitlistedAppointment",
    },
  },
  BOOKING: {
    SEARCH: {
      NEXT_APT: "searchNextApt",
    },
  },
  RESEND_MESSAGE: "resendConfirmMessage",

  SCHEDULE_ITEMS: {
    LIST: "listAppointmentScheduleItems",
    CREATE: "createAppointmentScheduleItems",
    UPDATE: "updateAppointmentScheduleItems",
    DELETE: "deleteAppointmentScheduleItems",
    RESET: "resetAppointmentScheduleItems",
  },
};

const AppointmentMutations = {
  SET_BOOKING: {
    LIST: "setBookingList",
    SELECT: "setSelectBooking",
    SEARCH: {
      VARIABLE: "setSearchVariable",
      DATE: "setFilteredBookingByDate",
      SPECIALISTS: "setFilteredBookingBySPT",
      FILLTEREDSPECIALISTS: "setFilteredBookingBySPT1",
      NEXT_APTS: "setNextAptList",
    },
  },
  DRAFT: {
    SET: "setAppointmentDraftId",
  },
  SET_APT: {
    LIST: "setApt",
    LISTBYID: "setAptById",
    SELECT: "setSelectApt",
    SELECT_SPECIALIST: "setSelectedSpecialist",
    TYPES: {
      LIST: "setAptType",
      SELECT: "setSelectAptType",
    },
    PRE_ADMISSION: {
      ORG: "setAptPreAdmissionOrg",
      VALIDATE: {
        DATA: "setValidateAptPreAdmission",
        MSG: "setValidateAptPreAdmissionMsg",
      },
    },
    USER_APT: {
      LIST: "setUserApt",
      SELECT: "setSelectUserApt",
    },
    REFERRAL: {
      UPDATE: "UpdateSelectedAppointmentReferral",
    },
  },
  SET_MONTH_AVAILABILITIES: "setMonthAvailabilities",
  SET_CLAIM: {
    LIST: "setClaimList",
  },
  MODULE_STATE: "setModuleState"
};

export { AppointmentActions, AppointmentMutations };
