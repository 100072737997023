import { createApp } from "vue";
import App from "./App.vue";

import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
//imports for app initialization
import ApiService from "@/core/services/ApiService";
import BillingApiService from "./core/services/BillingApiService";
import CodingApiService from "./core/services/CodingApiService";
import HealthLinkApiService from "./core/services/HealthLinkApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import VueGoogleMaps from "@fawmi/vue-google-maps";

import HeadingText from "./components/presets/GeneralElements/HeadingText.vue";
import CardSection from "./components/presets/GeneralElements/CardSection.vue";
import InputWrapper from "@/components/presets/FormElements/InputWrapper.vue";
import ModalWrapper from "@/components/presets/GeneralElements/ModalWrapper.vue";
import Button from "@/components/presets/GeneralElements/Button.vue";
import ButtonGroup from "@/components/presets/GeneralElements/ButtonGroup.vue";
import InfoSection from "@/components/presets/GeneralElements/InfoSection.vue";
import InlineSVG from "@/components/presets/GeneralElements/InlineSVG.vue";
import CurrencyInput from "@/components/presets/GeneralElements/CurrencyInput.vue";
import NavLink from "@/components/presets/GeneralElements/NavLink.vue";
import StatusBadge from "@/components/presets/GeneralElements/StatusBadge.vue";
import UserKnowledgeBaseModal from "@/components/help/UserKnowledgeBaseModal.vue";

import "bootstrap";
import VueSignaturePad from "vue-signature-pad";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueGtag from "vue-gtag";

// Only enable Flare in production, we don't want to waste your quota while you're developing:
const flareKey = import.meta.env.VITE_FLARE_KEY;
if (flareKey) {
  flare.light();
  const flareTest = import.meta.env.VITE_FLARE_TEST;
  if (flareTest) {
    flare.test();
  }
}

const app = createApp(App);

app.use(store);
app.use(router);
app.use(CKEditor);
app.use(ElementPlus, { size: "large", zIndex: 3000 });
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDCDe_kzdqziechOJ53yViPLc6hLQbhX1o",
    libraries: "places",
  },
});
app.use(VueSignaturePad);

ApiService.init(app);
BillingApiService.init();
CodingApiService.init();
HealthLinkApiService.init();
initInlineSvg(app);

app
  .component("HeadingText", HeadingText)
  .component("CardSection", CardSection)
  .component("InputWrapper", InputWrapper)
  .component("ModalWrapper", ModalWrapper)
  .component("Button", Button)
  .component("ButtonGroup", ButtonGroup)
  .component("InfoSection", InfoSection)
  .component("InlineSVG", InlineSVG)
  .component("NavLink", NavLink)
  .component("UserKnowledgeBaseModal", UserKnowledgeBaseModal)
  .component("StatusBadge", StatusBadge)
  .component("CurrencyInput", CurrencyInput);

if (["production"].includes(import.meta.env.MODE)) {
  app.use(
    VueGtag,
    {
      config: {
        id: "G-EL0B74L4F2",
        params: { anonymize_ip: true },
      },
    },
    router
  );
}

app.use(flareVue);

app.mount("#app");
