import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import { displayServerError } from "@/helpers/alertHelpers";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";

@Module
export default class FileModule extends VuexModule {
  @Action
  async [Actions.FILE.VIEW](path: string) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      try {
        const { data } = await ApiService.post("/file", path, {
          responseType: "blob",
        });
        return data;
      } catch ({ response }) {
        return displayServerError(response, "Viewing a File");
      }
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
